import { isDev } from "./util";

export const colors = {
    sunriseYellow: "#FF8100", //255,129,0
    blueBG: "#114B5F",
    greenBG: "#619B8A",
    aquaYellow: "#F1FEC6",
    paleYellow: "#EAD94C",
    brandBlue: "#364CE3",
}
export const FIREBASE_COLLECTION_KEY = !isDev() ? 'matka_firebase_collection_key' : 'test_collection';
export const FIREBASE_COLLECTION_DOC_KEY = !isDev() ? 'games_data' : 'test_doc';
export const ADMIN_LOGIN_KEY = 'admin_login_key';
export const ADMIN_LOGIN_TOKEN = 'admin_login_token';
export const SUPER_ADMIN_LOGIN_KEY = 'super_admin_login_key';


export enum ChartsType { 
    PANEL = 'PANEL_CHART',
    JODI = 'JODI_CHART'
}

export const ROUTE_PATHS = {
    HOME: '/',
    ADMIN_LOGIN: 'adminLogin',
    ADD_GAME: 'addGameScreen',
    ADD_FATAFAT_RESULT: 'addFatafatResult',
    FATAFAT_RESULT_DETAIL: 'fatafatResultDetailScreen',
    EDIT_FATAFAT_RESULT_DETAIL: 'editFatafatResultScreen',
    PANEL_CHARTS_DETAIL: 'panelChartScreen',
    JDOI_CHARTS_DETAIL: 'jodiChartScreen',
    HTML_CONTENT_SCREEN: 'htmlContentScreen',
    ADD_EDIT_GUESSING_TABLE_RESULT: 'addGuessingTableResult',
    SERVER_DOWN_SCREEN: 'serverDown',
}