import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
} from "./constants";
import { updateCollection } from "./services/firebase";
import { startOfWeek, addDays, format } from "date-fns";

import MainBazarData from "./data/tatamatka/main_bazaar.json";
import MilanData from "./data/tatamatka/milan.json";
import RajdhaniNightData from "./data/tatamatka/rajdhani_night.json";
import TataRajdhaniData from "./data/tatamatka/tata_rajdhani.json";
import TataTimeBazarData from "./data/tatamatka/tata_time_bazaar.json";
import KalyanData from "./data/tatamatka/kalyan.json";
import BhootnathData from "./data/tatamatka/bhootnath.json";
import KBombayData from "./data/tatamatka/kolkatabombay.json";
import { API_CONFIGS } from "./services/fetchAPI/constants";
import { APIService } from "./App";

export interface GameObject {
  lhsValue: string;
  createdTime: string;
  rhsValue: string;
  gamesTimeTableUI: {
    name: string;
    endTime: string;
    startTime: string;
  };
  name: string;
  endTime: string;
  winnerValue: string;
  startTime: string;
}
export const isDev = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export function isEmpty(value: any): boolean {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value === 'string' && value === ' ')
  );
}

export const insertSpaces = (str: string) => {
  if (Array.isArray(str)) {
    str = str.join(" ").replace(/ +/g, "");
  }
  if (typeof str !== "string") {
    return ""; // Return an empty string or handle the error as appropriate for your use case.
  }

  const regex = /^(\w{3})(\w{2})(\w+)/;
  const modifiedString = str.replace(regex, "$1 $2 $3");
  return modifiedString;
};

export const isObjectEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
  }
export const getSelectedParam = (params: any) => {
  if (params && !isObjectEmpty(params) && params.gameIndex >=0) {
    return params.gameIndex;
  }
  return null;
}

export const is11AMOrLater = () => {
  // Get the current date and time
  const currentDate = new Date();
  // Set the time to 10:00 AM in IST
  // const targetTime = new Date(currentDate);
  // targetTime.setHours(10, 0, 0);
  // targetTime.setMinutes(
  //   targetTime.getMinutes() + currentDate.getTimezoneOffset()
  // );
  const istOffset = 5.5 * 60 * 60 * 1000;
  const currentIstTime = new Date(currentDate.getTime() + istOffset);

  // Extract hours and minutes from the IST time
  const hours = currentIstTime.getUTCHours();
  // Compare the current time with the target time

  // console.log("is11AMOrLater {currentDate, targetTime} ==>.", {
  //   currentDate,
  //   // targetTime,
  //   hours,
  // });
  // if (currentDate >= targetTime) {
  if (hours >= 10) {
    console.log("Current time is 10 AM or later in IST.");
    return true;
  } else {
    console.log("Current time is before 10 AM in IST.");
    return false;
  }
};
export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}
export const onTimeChange = (inputTime) => {
  if (inputTime && (inputTime.includes('AM') || inputTime.includes('PM'))) {
    return inputTime;
  }
  try {
    let timeSplit = inputTime.split(":");
    let hours = 0;
    let minutes = 0;
    let meridian = "AM";
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0 || hours == parseInt("00")) {
        hours = 12;
      }
    } else {
      meridian = "PM";
    }
    return `${hours}:${minutes} ${meridian}`;
  } catch (e) {
    console.error("util onTimeChange error", e);
  }
};
export const getOldDataByName = (gameName: string) => {
  /**
   * Tata Time Bazaar
   * Tata Rajdhani
   * Milan
   * Bhoothnath
   * Rajdhani Night
   * Main Bazaar
   */
  const dataByNameObj = {
    "Main Bazaar": MainBazarData,
    Milan: MilanData,
    "Rajdhani Night": RajdhaniNightData,
    "Tata Rajdhani": TataRajdhaniData,
    "Tata Time Bazaar": TataTimeBazarData,
    Kalyan: KalyanData,
    Bhoothnath: BhootnathData,
    "Kbombay": KBombayData,
  };
  if (Object.keys(dataByNameObj).includes(gameName)) {
    return dataByNameObj[gameName];
  }
  return { data: [] };
};

export const trimAndSplitJodi = (passedValue) => {
  try {
    const splitVal = passedValue.trim().split(' ');
    return splitVal.length > 1 ? splitVal[1] : splitVal[0];
  } catch {
    return passedValue.trim();
  }
}

export const validatePhoneNumber = (phoneNumber: string) => {
  let errors = {};
  let isValid = true;
  // if (!input["Mobilenumber"]) {
  //     isValid = false;
  //     errors["Mobilenumber"] = "Please enter your Mobile Number.";
  // }
  if (phoneNumber.length === 0) {
    errors["mobilenumber"] = "";
    return {
      isValid,
      errors,
    };
  }
  if (typeof phoneNumber !== "undefined") {
    const pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    if (!pattern.test(phoneNumber)) {
      isValid = false;
      errors["mobilenumber"] = "Please Enter Number Only";
    } else if (phoneNumber.length < 10) {
      isValid = false;
      errors["mobilenumber"] = "Enter at least 10 digits mobile number.";
    } else if (phoneNumber.length > 10) {
      isValid = false;
      errors["mobilenumber"] = "Mobile number should be maximum of 10 digits.";
    }
  }

  return {
    isValid,
    errors,
  };
};

export const validatePANnumber = (panVal: string) => {
  const regpan = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);

  if (panVal.length === 0) {
    return {
      error: "",
    };
  }
  if (regpan.test(panVal)) {
    return {
      error: "",
    };
  } else {
    // invalid pan card number
    return {
      error: "Please enter valid PAN number.",
    };
  }
};

export const sumOfDigits = (num: number) => {
  let sum = 0;

  while (num) {
    sum += num % 10;
    num = Math.floor(num / 10);
  }
  return sum;
};
export const getLastDigitOfANumber = (num: number) => {
  return Number.isInteger(num) ? num % 10 : num.toString().slice(-1);
};
export const getCentralMatkaNumber = (lhsVal = 0, rhsVal = 0) => {
  const fistDigit =
    !isNaN(lhsVal) && lhsVal > 0
      ? getLastDigitOfANumber(sumOfDigits(lhsVal)).toString()
      : "*";
  const secondDigit =
    !isNaN(rhsVal) && rhsVal > 0
      ? getLastDigitOfANumber(sumOfDigits(rhsVal)).toString()
      : "*";

  return `${fistDigit}${secondDigit}`;
};
export const compareTimes = (obj1: any, obj2: any) => {
  try {
    const [hours1, minutes1] = obj1.gamesTimeTableUI.startTime.split(":").map(Number);
    const [hours2, minutes2] = obj2.gamesTimeTableUI.startTime.split(":").map(Number);

    if (hours1 !== hours2) {
      return hours1 - hours2;
    } else {
      return minutes1 - minutes2;
    }
  } catch (e) {
    console.error("compareTimes error", e);
    return 0;
  }
};
export const sortListByHHMMTime = (timeArray: any) => {
  return timeArray.sort(compareTimes);
};
//locale storage
export const saveItemInLocalStorage = (key: string, data: string) => {
  localStorage.setItem(key, data);
};

export const getItemFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error("Local storage item not found");
    return undefined;
  }
};

export const removeItemFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error("Local storage item not found for remove");
  }
};

//
export const getWeekDaysInMonth = () => {
  function getWeeksInMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

    const firstMonday =
      firstDayOfMonth.getDate() + ((1 - firstDayOfMonth.getDay() + 7) % 7);
    const lastSunday =
      lastDayOfMonth.getDate() - ((lastDayOfMonth.getDay() + 6) % 7);

    const weeks: any = [];
    let currentDay = new Date(currentYear, currentMonth, firstMonday);

    while (currentDay.getDate() <= lastSunday) {
      const startOfWeek = new Date(currentDay);
      const endOfWeek = new Date(currentDay.setDate(currentDay.getDate() + 6));

      weeks.push({ startOfWeek, endOfWeek });

      currentDay.setDate(currentDay.getDate() + 1);
    }

    return weeks;
  }

  // Usage:
  const weeksInMonth = getWeeksInMonth();
  console.log(weeksInMonth);
};
export const getWeeklyData = (data: any[]) => {
  const sortedData = data.sort(
    (a: any, b: any) =>
      new Date(a.createdTime.replace('Z', '')).valueOf() - new Date(b.createdTime.replace('Z', '')).valueOf()
  );
  let weeklyData: any = [];

  let startOfWeek: Date | null = null;
  let endOfWeek: Date | null = null;
  let currentWeekData = {};

  for (const entry of sortedData) {
    const currentDate = new Date(entry.createdTime.replace('Z', ''));

    if (!startOfWeek) {
      // First entry
      let startOfWeek = new Date(currentDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
      let endOfWeek: Date = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      let currentWeekData: any = {};
      currentWeekData[currentDate.getDay()] = [entry];
    } else if (
      (currentDate as Date) >= (startOfWeek as Date) &&
      endOfWeek !== null &&
      currentDate <= (endOfWeek as Date)
    ) {
      // Date is within the current week
      const dayOfWeek = currentDate.getDay();
      if (!currentWeekData[dayOfWeek]) {
        currentWeekData[dayOfWeek] = [entry];
      } else {
        currentWeekData[dayOfWeek].push(entry);
      }
    } else {
      // Date is in a new week
      weeklyData.push(currentWeekData);
      if (startOfWeek !== null && endOfWeek !== null) {
        (startOfWeek as Date).setDate((startOfWeek as Date).getDate() + 7);
        (endOfWeek as Date).setDate((endOfWeek as Date).getDate() + 7);
      }
      currentWeekData = {};
      currentWeekData[currentDate.getDay()] = [entry];
    }
  }

  // Add the last week's data
  weeklyData.push(currentWeekData);
  return weeklyData;

};

export const getDataByGameName = (data: any[], filterKey: string) => {
  return data.filter((item) => item.name === filterKey);
};

export const getWeekRangeVal = (obj: GameObject) => {
  const date = new Date(obj.createdTime.replace('Z', ''));
    const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Set week start as Monday
    const weekEnd = addDays(weekStart, 6); // Calculate week end as Sunday
    const weekStartString = format(weekStart, "dd/MM/yy");
    const weekEndString = format(weekEnd, "dd/MM/yy");

    return `${weekStartString} to ${weekEndString}`;
}
// get the list weekRange
export const getWeekwisedata = (data: GameObject[]) => {
  const segregatedData: { [week: string]: GameObject[] } = {};
  data.forEach((obj) => {
    const weekRange = getWeekRangeVal(obj);

    if (segregatedData[weekRange]) {
      segregatedData[weekRange].push(obj);
    } else {
      segregatedData[weekRange] = [obj];
    }
  });

  return segregatedData;
};
export const getEachWeekwisedata = (data: GameObject[]) => {
// Create an empty array to hold each date in the range
  const dateArray: any = [];
  const ob22 = data[0];
  const data2 = [ob22]
  data2.forEach((obj) => {
    const weekRange = getWeekRangeVal(obj);

    // Convert the date range to an array of dates
    const [startDateStr, endDateStr] = weekRange.split(' to ');

    // Convert date strings into Date objects
    const startDateParts = startDateStr.split('/');
    const endDateParts = endDateStr.split('/');

    const startDate = new Date(`20${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`);
    const endDate = new Date(`20${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`);

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        dateArray.push({
            date: new Date(d),
            item: {
              rhsValue: "***",
              lhsValue: "***",
              name: obj.gamesTimeTableUI.name,
              createdTime: obj.createdTime,
              startTime: obj.startTime,
              endTime: obj.endTime,
              gamesTimeTableUI: {
                name: obj.gamesTimeTableUI.name,
                startTime: obj.gamesTimeTableUI.startTime,
                endTime: obj.gamesTimeTableUI.endTime,
              },
              winnerValue: "**",
            }
        });
    }
  });
  // console.log("dayList dateArray:", dateArray)
  return dateArray;
};
export const getPanelJodiChartDetailData = (data: any[], filterKey: string) => {
  try {
    const dataByGameName = getPanelJodiChartData(data)[filterKey];
    if (dataByGameName) {
    const sortedData = dataByGameName.sort(
      (a: any, b: any) =>
        new Date(a.createdTime.replace('Z', '')).valueOf() - new Date(b.createdTime.replace('Z', '')).valueOf()
    );
    // console.log("dayList sortedData:", {sortedData})
    const weekwiseRangedata = getWeekwisedata(sortedData);
    // const weekwisedata = getEachWeekwisedata(sortedData);
    // console.log("dayList weekwiseRangedata:", weekwiseRangedata)
    // console.log("dayList weekwisedata:", weekwisedata)

    for (const [key, value] of Object.entries(weekwiseRangedata)) {
      if(value.length > 0 && value.length < 7) {
        while (value.length < 7) {
          weekwiseRangedata[key].push({
            rhsValue: "***",
            lhsValue: "***",
            name: value[0].gamesTimeTableUI.name,
            createdTime: value[0].createdTime,
            startTime: value[0].startTime,
            endTime: value[0].endTime,
            gamesTimeTableUI: {
              name: value[0].gamesTimeTableUI.name,
              startTime: value[0].gamesTimeTableUI.startTime,
              endTime: value[0].gamesTimeTableUI.endTime,
            },
            winnerValue: "**",
          });  // Push an item until the length reaches 7
        }
      }
    }

    const mappedData = Object.keys(weekwiseRangedata).map((weekRange) => {
      return {
        weekIntervalTime: weekRange,
        dayWiseData: weekwiseRangedata[weekRange]
      }
    // console.log("dayList finalData:", finalData)
    });
    // console.log("getPanelJodiChartDetailData mappedData:", mappedData)
    return mappedData;
    }
  } catch (e) {
    console.error("getPanelJodiChartDetailData error", e);
    return [];
  }
};

export const getPanelJodiChartData = (data: any[]) => {
  const segregatedData = {};
  data.forEach((obj) => {
    const gameName = obj.gamesTimeTableUI.name;

    if (segregatedData[gameName]) {
      segregatedData[gameName].push(obj);
    } else {
      segregatedData[gameName] = [obj];
    }
  });
  return segregatedData;
};

export const getFatafatResultsDetailData = (data: any[], desiredLength = 8) => {
  if (!data) {
    return [];
  }
  const sortedData = data.sort(
    (a: any, b: any) =>
      new Date(a.createdTime.replace('Z', '')).valueOf() - new Date(b.createdTime.replace('Z', '')).valueOf()
  );
  
  const segregatedData = sortedData.reduce((result, item) => {
    const { createdTime } = item;

    if (!isEmpty(createdTime)) {
      if (!result[createdTime.split("T")[0]]) {
        result[createdTime.split("T")[0]] = [];
      }
  
      result[createdTime.split("T")[0]].push(item);
    }
    return result;
  }, {});

  return Object.keys(segregatedData).map((item) => {
    // check for empty days
    let dayList = segregatedData[item];
    const currentLength = dayList.length;

    if (currentLength < desiredLength) {
      const numberOfItemsToAdd = desiredLength - currentLength;
      for (let i = 0; i < numberOfItemsToAdd; i++) {
        dayList.push({
          guessNumber: "***",
          createdTime: segregatedData[item][0].createdTime,
          startTime: segregatedData[item][0].startTime,
          winnerValue: "**",
        });
      }
    } else if (currentLength > desiredLength) {
      dayList.splice(desiredLength);
    }
    return {
      date: item,
      dateWiseData: segregatedData[item],
    };
  });
};

export const getAddUpdateGameInitialState = (data: any[], index = null) => {
  const initialState = {
    gameName: "",
    startTime: "",
    endTime: "",
    lhsValue: "",
    rhsValue: "",
    winnerValue: "",
    createdTime: "",
    guessGameTypeId: -1,
  };

  try {
    if (!isEmpty(index) && index != null) {
      // const foundObj = sortListByHHMMTime(data)[index];
      const foundObj = data[index];
      // const foundObj = data[parseInt(index)];

      return {
        ...initialState,
        gameName: foundObj?.name,
        startTime: foundObj?.startTime,
        endTime: foundObj?.endTime,
        lhsValue: foundObj?.lhsValue,
        rhsValue: foundObj?.rhsValue,
        winnerValue: foundObj?.winnerValue,
        createdTime: foundObj?.createdTime,
        guessGameTypeId: foundObj?.guessGameTypeId,
      };
    }
  } catch (e) {
    return initialState;
  }
  return initialState;
};
export const getGuessTableAddEditInitialState = (data: any[], guessGameId = null) => {
  const initialState = {
    gameName: "",
    guessNumber: "",
    winnerValue: "",
    createdTime: "",
    guessGameId: null, 
  };

  try {
    const foundIndex = data.findIndex(ele => ele.guessGameId === parseInt(guessGameId ?? '-001'));
    if (foundIndex > -1) {
      const foundObj = data[foundIndex];

      return {
        ...initialState,
        gameName: foundObj.gameName,
        guessNumber: foundObj.guessNumber,
        winnerValue: foundObj?.winnerValue,
        createdTime: foundObj?.createdTime,
        guessGameId: foundObj?.guessGameId
      };
    }
  } catch (e) {
    return initialState;
  }
  return initialState;
};
export const getFatafatTableAddEditInitialState = (data: any[], fatafatGuessId = null) => {
  const initialState = {
    guessNumber: "",
    winnerValue: "",
    createdTime: "",
    startTime: "",
    fatafatGuessId: null, 
  };

  try {
    const foundIndex = data.findIndex(ele => ele.fatafatGuessId === parseInt(fatafatGuessId ?? '-001'));
    if (foundIndex > -1) {
      const foundObj = data[foundIndex];

      return {
        ...initialState,
        guessNumber: foundObj.guessNumber,
        winnerValue: foundObj?.winnerValue,
        createdTime: foundObj?.createdTime,
        fatafatGuessId: foundObj?.fatafatGuessId,
        startTime: foundObj?.startTime
      };
    }
  } catch (e) {
    return initialState;
  }
  return initialState;
};

export const getCurrentDayGames = (data: any[]) => {
  // new Date().toLocaleDateString()
  /**
   * Tata Time Bazaar
   * Tata Rajdhani
   * Milan
   * Bhoothnath
   * Rajdhani Night
   * Main Bazaar
   */
  try {
    const currentdayGames = data.filter((item) => {
      const todayDate = new Date();
      const createdDateTime = new Date(item.createdTime.replace('Z', ''));
      
      if (
        createdDateTime.getDate() === todayDate.getDate() &&
        createdDateTime.getMonth() === todayDate.getMonth() &&
        createdDateTime.getFullYear() === todayDate.getFullYear()
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (currentdayGames.length > 0) {
      return currentdayGames;
    } else if (!is11AMOrLater()) {
      return data.filter((item) => {
        const todayDate = new Date();
        const createdDateTime = new Date(item.createdTime.replace('Z', ''));
        if (
          createdDateTime.getDate() === todayDate.getDate() - 1 &&
          createdDateTime.getMonth() === todayDate.getMonth() &&
          createdDateTime.getFullYear() === todayDate.getFullYear()
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return [];
    }
  } catch (e) {
    console.log("getCurrentDayGames error", e);
    return [];
  }
};
export const getPastDaysGames = (data: any[]) => {
  if (!is11AMOrLater()) {
    return data.filter(
      (item) => new Date(item.createdTime.replace('Z', '')).getDate() !== new Date().getDate() - 1
    );
  }
  return data.filter(
    (item) => new Date(item.createdTime.replace('Z', '')).getDate() !== new Date().getDate()
  );
};

export const getYesrdaysDaysGames = (data: any[]) => {
  return data.filter(
    (item) => new Date(item.createdTime.replace('Z', '')).getDate() === new Date().getDate() - 1
  );
};

export const createGamesForTodayAPICall = (gamesList: any, callback) => {
  const todaysGames = gamesList.map((item) => {
    return {
      // name: item.name,
      // startTime: item.startTime,
      // endTime: item.endTime,
      gamesTimeTableId: item.gamesTimeTableId,
      lhsValue: "***",
      rhsValue: "***",
      winnerValue: "**",
      createdTime: new Date().toISOString(),
    };
  });
  let apiCalled = 0;
  todaysGames.forEach((obj) => {
    apiCalled++;
    APIService.post(API_CONFIGS.CREATE_GAME.URL, obj)
    .then((response: any) => {
      console.log("createGamesForTodayAPICall API_CONFIGS.CREATE_GAME.URL", response);
      if (response?.data?.status && response?.data?.model) {
        apiCalled++;
      } else if (response?.data?.errMessages && response?.data?.errMessages.length > 0) {
        const errorMSg = response.data.errMessages[0].errMessage;
        throw new Error(` error.: ${errorMSg}`); 
      }
    })
    .catch((error) => {
      console.error("Error createGamesForTodayAPICall:", error);
    });
  });
  console.log('todaysGames apiCalled:::', apiCalled)
  apiCalled === todaysGames.length && callback(true);  
}
export const createGamesForCurrentDay = (data: any, callback) => {
  const pastDaysGameList: any[] = getPastDaysGames(data.gameTypes);
  const sortedGameTimeTableList = sortListByHHMMTime(data.gamesTimeTable);
  const todaysGames = sortedGameTimeTableList.map((item) => {
    return {
      name: item.name,
      startTime: item.startTime,
      endTime: item.endTime,
      gamesTimeTableId: item.gamesTimeTableId,
      lhsValue: "***",
      rhsValue: "***",
      winnerValue: "**",
      createdTime: new Date().toISOString(),
    };
  });
  let updatedData = {
    ...data,
    gameTypes: [...new Set([...pastDaysGameList, ...todaysGames])],
  };
  console.log("createGamesForCurrentDay", { updatedData });
  updateCollection(
    FIREBASE_COLLECTION_KEY,
    FIREBASE_COLLECTION_DOC_KEY,
    updatedData
  )
    .then((response) => {
      console.info(
        "firebase updated successfully for createGamesForCurrentDay!",
        response
      );
      callback(true);
    })
    .catch((e) => {
      console.error("firebase updated failed createGamesForCurrentDay!", e);
    });
};

// const removeDuplicate = (arrayVal) => {
//   let uniqueTime = [];
//   let uniqueArrays = [];
//   arrayVal.forEach(element => {
//     if (!uniqueTime.includes(element.name)) {
//       uniqueTime.push(element.name);
//       uniqueArrays.push(element);
//     }
//   });
//   return uniqueArrays;
// };