import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/icon-192.png";
import { ROUTE_PATHS } from "../../../constants";

const NavBarHeader = ({ isAdminLoggedIn, handleLogout, isServerDown }) => {
  return (
    <div className="container-fluid">
      <div className="row gold-top-hed">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-4">
          <div className="gold-logo">
            <Link to={ROUTE_PATHS.HOME} title="Register with us">
              {/* <a href="./index.html"> */}
              <img
                src={Logo}
                alt="satta matka online logo"
                style={{ width: 35, height: 35 }}
              />
              {/* </a> */}
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-8">
          {!isServerDown && (
            <div className="gold-login-area">
              <ul>
                <li>
                  {!isAdminLoggedIn ? (
                    <Link
                      to={ROUTE_PATHS.ADMIN_LOGIN}
                      title="Register with us"
                      className="log-inn"
                    >
                      {/* <a
             className="log-inn"
             href="./html/admin.html"
             data-text="admin-login"
           > */}
                      <i className="fa fa-lock" aria-hidden="true"></i>
                      <p>Login</p>
                    </Link>
                  ) : (
                    <div onClick={handleLogout} className="logout-container">
                      <a
                        className="log-out"
                        href="./html/admin.html"
                        data-text="admin-logout"
                      >
                        <i className="fa fa-lock" aria-hidden="true"></i>
                        <p>Logout</p>
                      </a>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default NavBarHeader;
